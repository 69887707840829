<template>
  <div class="space-y-4">
    <asom-card title="Email Templates">
      <asom-alert v-if="emailTemplates.error" variant="error" :error-message="emailTemplates.error" />
      <div class="w-full text-center" v-else-if="emailTemplates.isLoading">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
      <asom-client-table
        :columns="[
          'notificationCategory',
          'templateSubject',
          'action'
        ]"
        :data="emailTemplates.list"
        :pagination="false"
        :filterable="false"
      >
        <template v-slot:header_notificationCategory>Category</template>
        <template v-slot:header_templateSubject>Email Title</template>
        <template v-slot:action="scopedSlots">
          <div class="flex space-x-2">
            <asom-button
              text="Edit"
              @click="$router.push({ name: 'Edit Email Template', params: scopedSlots.rowData })"
            />
          </div>
        </template>
      </asom-client-table>
    </asom-card>
  </div>
</template>

<script>
import get from 'lodash.get';
import { mapGetters } from 'vuex';
import { LIST_EMAIL_TEMPLATE } from '../../constants/apis';
import { getAsync } from '../../services/serviceHandlers';
export default {
  data() {
    return {
      emailTemplates: {
        list: [],
        error: null,
        isLoading: false,
      },
      faultConfig: {
        threshold: -1,
        error: null,
        isLoading: false,
      },
      opsCommConfig: {
        threshold: -1,
        error: null,
        isLoading: false,
      }
    }
  },
  mounted() {
    this.loadEmailTemplates();
  },
  computed: {
    ...mapGetters({
      lineId: 'auth/userLineId'
    })
  },
  methods: {
    async loadEmailTemplates() {
      this.emailTemplates.error = null;
      this.emailTemplates.isLoading = true;
      const templateResults = await getAsync({
        url: LIST_EMAIL_TEMPLATE,
        params: {
          lineId: this.lineId
        }
      });
      if (templateResults.success) {
        this.emailTemplates.list = get(templateResults, 'payload.list', []);
      } else {
        this.emailTemplates.error = templateResults.payload;
      }
      this.emailTemplates.isLoading = false;
    }
  }
}
</script>

<style>

</style>